const teamFeatures = [
  {
    label: "Account Features",
    list: [
      "1TB storage",
      "20 monthly screener links",
      "Custom user permissions and roles",
      "HTTP uploads and downloads",
      "Aspera file requests",
      "Send Aspera links ($0.50/GB)"
    ]
  },
  {
    label: "Screeners",
    list: [
      "Stunning 1080p (adaptive bitrate)",
      "Studio-grade DRM",
      "Geoblocking",
      "Password protection",
      "View count limit",
      "Single device restriction",
      "Reviewer-specific visible watermarks",
      "Share multiple screeners together",
      "Reviewer-specific analytics",
      "Works with CineSend Screeners AppleTV, Roku, FireTV, and AndroidTV viewing apps"
    ]
  }
]

const enterpriseFeatures = [
  {
    label: "Account Features",
    list: [
      "Configurable storage",
      "Unlimited screener links",
      "Custom user permissions and roles",
      "*Two-factor authentication",
      "*SSO via OpenID, SAML, etc.",
      "HTTP uploads and downloads",
      "Aspera file requests",
      "Send Aspera links"
    ]
  },
  {
    label: "Screeners",
    list: [
      "*Up to 4K (adaptive bitrate)",
      "Studio-grade DRM",
      "*Custom DRM security-level configurations",
      "Geoblocking",
      "Password protection",
      "View count limit",
      "Single device restriction",
      "Reviewer-specific visible watermarks",
      "*NexGuard forensic watermarks",
      "*Availability date ranges",
      "Share multiple screeners together",
      "Reviewer-specific analytics",
      "*Branded landing pages",
      "*Branded emails",
      "*Branded AppleTV, Roku, FireTV, AndroidTV, Tizen, and WebOS viewing apps"
    ]
  },
  {
    label: "Premier Support",
    list: ["*24/7 email and phone support", "*Dedicated account manager", "*Migration assistance"]
  }
]

const teamPrices = {
  monthly: {
    usd: {
      human_readable_price: "99",
      currency_code: "USD",
      currency_symbol: "$"
    },
    cad: {
      human_readable_price: "120",
      currency_code: "CAD",
      currency_symbol: "$"
    },
    inr: {
      human_readable_price: "7300",
      currency_code: "INR",
      currency_symbol: "₹"
    },
    gbp: {
      human_readable_price: "70",
      currency_code: "GBP",
      currency_symbol: "£"
    },
    eur: {
      human_readable_price: "82.50",
      currency_code: "EUR",
      currency_symbol: "€"
    }
  },
  yearly: {
    usd: {
      human_readable_price: "1070",
      currency_code: "USD",
      currency_symbol: "$"
    },
    cad: {
      human_readable_price: "1296",
      currency_code: "CAD",
      currency_symbol: "$"
    },
    inr: {
      human_readable_price: "78840",
      currency_code: "INR",
      currency_symbol: "₹"
    },
    gbp: {
      human_readable_price: "756",
      currency_code: "GBP",
      currency_symbol: "£"
    },
    eur: {
      human_readable_price: "890",
      currency_code: "EUR",
      currency_symbol: "€"
    }
  }
}

const plans = [
  {
    label: "Teams",
    description: "For small and medium-sized teams.",
    link: "https://accounts.cinesend.com/signup",
    button_text: "Get Started",
    features: teamFeatures,
    price: teamPrices,
    additional_price: "$12 per additional user"
  },
  {
    label: "Enterprise",
    description: "For larger teams or those with more sophisticated security needs.",
    link: "/demo",
    button_text: "Contact Sales",
    is_enterprise: true,
    features: enterpriseFeatures
  }
]

export default plans
