const addons = [
  {
    name: "+ 1TB Storage",
    extras: "Available on all paid plans",
    type: "subscription",
    price: {
      monthly: {
        usd: {
          human_readable_price: "30",
          currency_code: "USD",
          currency_symbol: "$"
        },
        cad: {
          human_readable_price: "40",
          currency_code: "CAD",
          currency_symbol: "$"
        },
        inr: {
          human_readable_price: "2,100",
          currency_code: "INR",
          currency_symbol: "₹"
        },
        gbp: {
          human_readable_price: "23",
          currency_code: "GBP",
          currency_symbol: "£"
        },
        eur: {
          human_readable_price: "26",
          currency_code: "EUR",
          currency_symbol: "€"
        }
      },
      yearly: {
        usd: {
          human_readable_price: "324",
          currency_code: "USD",
          currency_symbol: "$"
        },
        cad: {
          human_readable_price: "432",
          currency_code: "CAD",
          currency_symbol: "$"
        },
        inr: {
          human_readable_price: "22,680",
          currency_code: "INR",
          currency_symbol: "₹"
        },
        gbp: {
          human_readable_price: "249",
          currency_code: "GBP",
          currency_symbol: "£"
        },
        eur: {
          human_readable_price: "281",
          currency_code: "EUR",
          currency_symbol: "€"
        }
      }
    }
  },

  {
    name: "+ 10 Screener Credits",
    extras: "Available on all plans",
    type: "purchase",
    suffix: " / 10 Credits",
    price: {
      usd: {
        human_readable_price: "20",
        currency_code: "USD",
        currency_symbol: "$"
      },
      cad: {
        human_readable_price: "30",
        currency_code: "CAD",
        currency_symbol: "$"
      },
      inr: {
        human_readable_price: "1,000",
        currency_code: "INR",
        currency_symbol: "₹"
      },
      gbp: {
        human_readable_price: "20",
        currency_code: "GBP",
        currency_symbol: "£"
      },
      eur: {
        human_readable_price: "17.50",
        currency_code: "EUR",
        currency_symbol: "€"
      }
    }
  },

  {
    name: "+ Prepaid Aspera Commitments",
    extras: "3TB, 6TB, 12TB, 24TB, 48TB",
    type: "contact"
  }
]

export default addons
