import React from "react"
import faqs from "@data/pricing/faqs.json"
import Fade from "@app-components/transition/fade"
import Background from "@app-components/utils/background"

const Faqs = () => (
  <Fade tagName="section" className="my5" opacity up>
    <Background tagName="div" className="px2 md-px3" type="gray-double">
      <div>
        <h2 className="center mb4">{faqs.title}</h2>
        <div className="max-width-5 mx-auto flex flex-wrap">
          {faqs.questions.map(q => (
            <div key={q.title} className="col-12 lg-col-6 mb3 px3">
              <strong style={{ fontSize: "16px" }} className="block">
                {q.title}
              </strong>
              <p>{q.text}</p>
            </div>
          ))}
        </div>
      </div>
    </Background>
  </Fade>
)

export default Faqs
