import React from "react"
import { css } from "@emotion/core"
import plans from "@data/pricing/"
import addons from "@data/pricing/addons"
import Link from "gatsby-link"

const Plans = () => {
  const planType = "monthly"
  const currency = "USD"
  return (
    <section className="px2 md-px3">
      <div className="flex flex-wrap items-stretch max-width-4 mx-auto mb3">
        {plans.map(plan => (
          <div
            key={plan.label}
            className="col-12 sm-col-12 md-col-6 lg-col-6 mx-auto px1 lg-mb0 mb4f lg-left-align max-width-2">
            <div
              css={css`
                height: 100%;
                border-radius: 8px;
                box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
              `}
              className="bg-white p3">
              <div className="flex justify-between">
                <div style={{ height: "60px" }}>
                  <h2>{plan.label}</h2>
                </div>
                {plan.price && (
                  <div className="relative inline-block pl2">
                    <div
                      css={css`
                        font-size: 18px;
                      `}
                      className="absolute top-0 left-0">
                      {plan.price[planType][currency.toLowerCase()].currency_symbol}
                    </div>
                    <span
                      css={css`
                        font-size: 48px;
                        line-height: 48px;
                      `}>
                      {plan.price[planType][currency.toLowerCase()].human_readable_price}
                    </span>
                    <strong className="ml1">
                      {currency.toUpperCase()}
                      {planType === "monthly" ? " / Month" : " / Year"}
                      {plan.price.is_per_seat ? " / User" : ""}
                    </strong>
                  </div>
                )}
              </div>
              <div className="flex justify-between mb2">
                <p>{plan.description}</p>
                {plan.additional_price && <p>{plan.additional_price}</p>}
              </div>
              <a href={plan.link} className="block mb3" target="_blank" rel="noopener noreferrer">
                <button tabIndex="-1" className={`col-12 block ${plan.is_enterprise ? "web_white" : ""}`}>
                  {plan.button_text}
                </button>
              </a>
              <ul className="pl0">
                {plan.features.map((section, i) => (
                  <div key={i}>
                    <div className="mt2 mb1 bold">{section.label.toUpperCase()}</div>
                    {section.list.map((item, j) => (
                      <li
                        key={j}
                        style={{ color: item.startsWith("*") ? "darkgreen" : "" }}
                        className={`list-style-none flex items-center py1 ${item.startsWith("*") ? "bold" : ""}`}>
                        <span className="material-icons mr1">check</span>
                        {item.replace("*", "")}
                      </li>
                    ))}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div className="flex flex-wrap items-stretch max-width-4 mx-auto mb3">
        {addons.map((addon, i) => (
          <div
            className="col-12 mx-auto px1 mb2"
            css={css`
              height: 105px;
            `}
            key={i}>
            <div
              css={css`
                height: 100%;
                border-radius: 8px;
                box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
              `}
              className="flex bg-white p3 items-center">
              <div className="flex-auto flex items-center">
                <h4 className="mr1">{addon.name}</h4>
                <span
                  css={css`
                    margin-top: 2px;
                    font-size: 1em;
                  `}>
                  ({addon.extras})
                </span>
              </div>

              {addon.type === "subscription" && (
                <div className="relative inline-block pl2">
                  <div
                    css={css`
                      font-size: 18px;
                    `}
                    className="absolute top-0 left-0">
                    {addon.price[planType][currency.toLowerCase()].currency_symbol}
                  </div>
                  <span
                    css={css`
                      font-size: 48px;
                      line-height: 48px;
                    `}>
                    {addon.price[planType][currency.toLowerCase()].human_readable_price}
                  </span>
                  <strong className="ml1">
                    {currency}
                    {planType === "monthly" ? " / Month" : " / Year"}
                  </strong>
                </div>
              )}

              {addon.type === "purchase" && (
                <div className="relative inline-block pl2">
                  <div
                    css={css`
                      font-size: 18px;
                    `}
                    className="absolute top-0 left-0">
                    {addon.price[currency.toLowerCase()].currency_symbol}
                  </div>
                  <span
                    css={css`
                      font-size: 48px;
                      line-height: 48px;
                    `}>
                    {addon.price[currency.toLowerCase()].human_readable_price}
                  </span>
                  <strong className="ml1">
                    {currency}
                    {addon.suffix}
                  </strong>
                </div>
              )}

              {addon.type === "contact" && (
                <div
                  css={css`
                    font-size: 1.5em;
                  `}>
                  <Link to="/contact">
                    <button
                      className="white"
                      css={css`
                        background: white !important;
                      `}>
                      Contact Sales
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <p className="center light">
        All fees, including monthly subscription fees, are subject to applicable local taxes.
      </p>
    </section>
  )
}

export default Plans
