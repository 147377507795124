import React from "react"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Faqs from "@page-components/products/files/pricing/faqs/"
import Plans from "@page-components/products/files/pricing/plans/"
import Navigation from "@app-components/ui/header/components/navigation"
import HEADERS from "@data/headers/"

const Pricing = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="products/files/pricing">
    <Background className="bg-cover bg-no-repeat bg-center" tagName="section" type="red-single">
      <Navigation navigation={HEADERS.files} />
      <div
        style={{
          paddingTop: "12rem",
          paddingBottom: "14rem",
          marginBottom: "-13rem"
        }}
        className="white center bg-cover px2 md-px3 md-px0 pb4">
        <h1>Flexible pricing for teams of any size</h1>
        <p className="large-p-tag max-width-2 mx-auto mt1">
          Start with our base team plan and add additional users, storage, and more as you scale up.
        </p>
      </div>
    </Background>
    <Plans />
    <Faqs />
  </Layout>
)

export default Pricing
